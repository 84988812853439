<template>
    <div>
        <button class="btn btn-primary text-uppercase" data-bs-toggle="modal" data-bs-target="#modalAddCharge">
            <i class="fa-regular fa-plus me-1"></i>Añadir extra
        </button>
        <!-- Modal Add Huesped-->
        <div class="modal fade" id="modalAddCharge" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content p-2">
                    <form @submit.stop.prevent="add" @change="updatePrice">
                        <div class="modal-header border-0">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Añadir Extra
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label class="form-label">Selecciona un extra</label>
                                <select class="form-control" v-model="addCharge.extra" required @change="updateExtra">
                                    <option value="0" selected>Otro</option>
                                    <option :key="item" v-for="item in extras.filter(item => item.status)" :value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Descripción</label>
                                <input type="text" class="form-control" v-model="addCharge.description" required />
                            </div>
                            <div class="row">
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">Segmentación</label>
                                    <select class="form-control" v-model="addCharge.segment" required>
                                        <option :key="item" v-for="item in segments" :value="item.id">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">Cantidad por día</label>
                                    <input type="number" class="form-control" v-model="addCharge.quantity" @change="updatePrice" required />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">Precio unitario ({{ defaultCurrency
                                        }})</label>
                                    <input type="text" class="form-control" v-model="addCharge.price_unit" @change="updatePrice" required />
                                </div>
                                <div class="form-group mb-3 col-6">
                                    <label class="form-label">IVA</label>
                                    <select class="form-control" v-model="addCharge.iva" required>
                                        <option value="0">0%</option>
                                        <option value="4">4%</option>
                                        <option value="6">6%</option>
                                        <option value="8">8%</option>
                                        <option value="10">10%</option>
                                        <option value="19">19%</option>
                                        <option value="21">21%</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="form-label">Indicar fechas</label>
                                <Calendar :attributes="attributes" :is-required="true" @dayclick="onDayClick" style="width: 100%" />
                            </div>
                            <div class="form-group mb-3">
                                <h5>
                                    Total:
                                    {{ formatCurrency(addCharge.price, defaultCurrency) }}
                                </h5>
                            </div>
                            <div v-if="error_message" class="alert alert-danger" role="alert">
                                {{ error_message }}
                            </div>
                        </div>
                        <div class="modal-footer border-0">
                            <a type="button" class="btn btn-link text-decoration-none text-primary" data-bs-dismiss="modal">Cerrar</a>
                            <button id="submitAddCharge" type="submit" class="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import ChargeService from "../../services/charge.service";
import BookingService from "../../services/booking.service";

import Swal from "sweetalert2";

export default {
    name: "AddCharge",
    props: {
        bookingroom: Object,
        booking: Object,
        extras: Array,
        segments: Array,
    },
    data() {
        return {
            addCharge: {
                booking: this.bookingroom.booking,
                bookingRoom: this.bookingroom.id,
                isExtra: true,
                extra: 0,
                description: null,
                price_unit: 0,
                price: 0,
                quantity: 1,
                segment: null,
                iva: "21",
                isOld: false,
                date: this.bookingroom.checkin,
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            error_message: null,
            attributes: [],
            defaultCurrency: '',
        };
    },
    computed: {
        dates() {
            return this.days.map((day) => day.date);
        },
    },
    methods: {
        async start() {
            var arr = [];
            const arrDates = this.getDates(
                new Date(this.bookingroom.checkin),
                new Date(this.bookingroom.checkout)
            );
            arrDates.forEach(function (date) {
                arr.push(date.toISOString().split("T")[0]);
            });

            this.attributes.push({
                highlight: true,
                dates: arr,
            });
        },

        getDates(startDate, endDate) {
            const dates = [];
            let currentDate = startDate;
            const addDays = function (days) {
                const date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
            while (currentDate <= endDate) {
                dates.push(currentDate);
                currentDate = addDays.call(currentDate, 1);
            }
            return dates;
        },

        async updatePrice() {
            var price = this.addCharge.price_unit;
            price = price * this.addCharge.quantity;
            price = price * this.attributes[0].dates.length;
            this.addCharge.price = Math.round(price * 100) / 100;
        },

        async updateExtra() {
            var extraID = this.addCharge.extra;
            if (extraID > 0) {
                for (var i = 0; i < this.extras.length; i++) {
                    if (this.extras[i].id == extraID) {
                        this.addCharge.description = this.extras[i].name;
                        this.addCharge.segment = this.extras[i].segment;
                        this.addCharge.price_unit = this.extras[i].price;
                        this.addCharge.iva = this.extras[i].iva;
                        break;
                    }
                }
            } else {
                this.addCharge.description = null;
                this.addCharge.segment = null;
                this.addCharge.iva = 21;
                this.addCharge.price_unit = 0;
            }
        },

        async updateBookingTotalPrice(price) {
            price = Math.round(price * 100) / 100;
            var response = await BookingService.updateBooking(this.booking.id, {
                price: price,
            });
            return response;
        },

        async updateBookingRoomTotalPrice(price) {
            var totalPrice =
                this.bookingroom.totalPrice + Math.round(price * 100) / 100;
            var response = await BookingService.updateBookingRoom(
                this.bookingroom.id,
                {
                    totalPrice: totalPrice,
                }
            );
            return response;
        },

        async add() {
            this.error_message = null;
            if (this.attributes[0].dates.length < 1) {
                this.error_message = "Debes seleccionar al menos una fecha.";
            } else {
                window.$("#submitAddCharge").empty();
                window
                    .$("#submitAddCharge")
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                this.addCharge.dates = this.attributes[0].dates;

                this.addCharge.price_unit = parseFloat(
                    this.addCharge.price_unit
                );
                this.addCharge.price = parseFloat(this.addCharge.price);

                let response = await ChargeService.createNewChargeToBooking(
                    this.addCharge
                );

                window.$("#submitAddCharge").empty();
                window.$("#submitAddCharge").append("Guardar");

                if (response.status == 200) {
                    window.$("#modalAddCharge").hide();
                    window.$("body").removeClass("modal-open");
                    window.$(".modal-backdrop").remove();

                    Swal.fire({
                        icon: "success",
                        title: "Cargo añadido",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        this.$router.go();
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
        },

        onDayClick(day) {
            if (!day.isDisabled) {
                const idx = this.attributes[0].dates.findIndex(
                    (d) => d === day.id
                );
                if (idx >= 0) {
                    this.attributes[0].dates.splice(idx, 1);
                } else {
                    this.attributes[0].dates.push(day.id);
                }
                this.updatePrice();
            }
        },

    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
};
</script>