<template>
    <div>
        <div class="d-grid gap-2 col-12 mx-auto">
            <button class="btn btn-info mx-2" data-bs-toggle="modal" data-bs-target="#modalAddCharge">
                Añadir cargo
            </button>
        </div>
        <!-- Modal Add Huesped-->
        <div class="modal fade" id="modalAddCharge" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content p-2">
                    <div class="modal-header border-0">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Añadir cargos a la factura
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <div class="btn-group w-100 mb-4" role="group">
                            <input v-if="!isNewCharge" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked @click="optionCharge(false)" />
                            <input v-else type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" @click="optionCharge(false)" />
                            <label class="btn btn-outline-primary" for="btnradio1">Lista de
                                cargos</label>

                            <input v-if="!isNewCharge" type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" @click="optionCharge(true)" />
                            <input v-else type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked @click="optionCharge(true)" />
                            <label class="btn btn-outline-primary" for="btnradio2">Crear nuevo
                                cargo</label>
                        </div>

                        <div v-if="!isNewCharge">
                            <div v-if="invoice.isProforma">
                                <p>
                                    <small>Selecciona las habitaciones y cargos
                                        que quieras añadir a la factura</small>
                                </p>
                                <div :key="BR" v-for="BR in charges" class="mb-3 invoice-add-charges">
                                    <h6>
                                        {{
                                            BR.roomType_name +
                                            ": " +
                                            BR.room_name +
                                            " (" +
                                            formatCurrency(
                                                BR.totalPrice,
                                                defaultCurrency
                                            ) +
                                        ")"
                                        }}
                                    </h6>
                                    <div class="list-group mb-2">
                                        <label :id="'charge-' + charge.id" class="list-group-item" :disabled="invoice.charges.includes(
                                            charge.id
                                        )
                                            " :key="charge" v-for="charge in BR.charges">
                                            <div class="d-flex align-items-center">
                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem" @change="addCharge(charge)" :disabled="invoice.charges.includes(
                                                    charge.id
                                                )
                                                    " :checked="invoice.charges.includes(
                                    charge.id
                                )
                                    " />
                                                <div class="d-flex align-items-center justify-content-between ms-3">
                                                    <div style="width: 80px">
                                                        {{ charge.date }}
                                                    </div>
                                                    <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                    <span v-if="charge.isAccommodation
                                                    ">{{
                                    "Alojamiento: " +
                                    charge.description +
                                    " (" +
                                    formatCurrency(
                                        charge.price,
                                                        defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                    <span v-else>{{
                                                        "Extra: " +
                                                        charge.description +
                                                        " (" +
                                                        formatCurrency(
                                                            charge.price,
                                                            defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="chargesTaxes.length > 0" class="invoice-add-charges">
                                    <p>
                                        <small>Selecciona los impuestos que
                                            quieras añadir a la factura</small>
                                    </p>
                                    <div class="list-group mb-2">
                                        <label :id="'charge-' + charge.id" class="list-group-item" :disabled="invoice.charges.includes(
                                            charge.id
                                        )
                                            " :key="charge" v-for="charge in chargesTaxes">
                                            <div class="d-flex align-items-center">
                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem" @change="addCharge(charge)" :disabled="invoice.charges.includes(
                                                    charge.id
                                                )
                                                    " :checked="invoice.charges.includes(
                                    charge.id
                                )
                                    " />
                                                <div class="d-flex align-items-center justify-content-between ms-3">
                                                    <div style="width: 80px">
                                                        {{ charge.date }}
                                                    </div>
                                                    <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                    <span>{{
                                                        charge.description +
                                                        " (" +
                                                        formatCurrency(
                                                            charge.price,
                                                        defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="chargesAdvancePayments.length > 0" class="invoice-add-charges">
                                    <p>
                                        <small>Selecciona los anticipos que
                                            quieras añadir a la factura</small>
                                    </p>
                                    <div class="list-group mb-2">
                                        <label :id="'charge-' + charge.id" class="list-group-item" :disabled="invoice.charges.includes(
                                            charge.id
                                        )
                                            " :key="charge" v-for="charge in chargesAdvancePayments">
                                            <div class="d-flex align-items-center">
                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem" @change="addCharge(charge)" :disabled="invoice.charges.includes(
                                                    charge.id
                                                )
                                                    " :checked="invoice.charges.includes(
                                    charge.id
                                )
                                    " />
                                                <div class="d-flex align-items-center justify-content-between ms-3">
                                                    <div style="width: 80px">
                                                        {{ charge.date }}
                                                    </div>
                                                    <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                    <span>{{
                                                        "ANTICIPO: " +
                                                        charge.description +
                                                        " (" +
                                                        formatCurrency(
                                                            charge.price,
                                                        defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p>
                                    <small>Selecciona las habitaciones y cargos
                                        que quieras añadir a la factura</small>
                                </p>
                                <div :key="BR" v-for="BR in charges" class="mb-3 invoice-add-charges">
                                    <h6>
                                        {{
                                            BR.roomType_name +
                                            ": " +
                                            BR.room_name +
                                            " (" +
                                            formatCurrency(
                                                BR.totalPrice,
                                                defaultCurrency
                                            ) +
                                        ")"
                                        }}
                                    </h6>
                                    <div class="list-group mb-2">
                                        <label :id="'charge-' + charge.id" class="list-group-item" :disabled="charge.availableToInvoice ==
                                            false
                                            " :key="charge" v-for="charge in BR.charges">
                                            <div class="d-flex align-items-center">
                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem" @change="addCharge(charge)" :disabled="charge.availableToInvoice ==
                                                    false
                                                    " :checked="charge.availableToInvoice ==
                                    false
                                    " />
                                                <div class="d-flex align-items-center justify-content-between ms-3">
                                                    <div style="width: 80px">
                                                        {{ charge.date }}
                                                    </div>
                                                    <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                    <span v-if="charge.isAccommodation
                                                    ">{{
                                    "Alojamiento: " +
                                    charge.description +
                                    " (" +
                                    formatCurrency(
                                        charge.price,
                                                        defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                    <span v-else>{{
                                                        "Extra: " +
                                                        charge.description +
                                                        " (" +
                                                        formatCurrency(
                                                            charge.price,
                                                            defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="chargesTaxes.length > 0" class="invoice-add-charges">
                                    <p>
                                        <small>Selecciona los impuestos que
                                            quieras añadir a la factura</small>
                                    </p>
                                    <div class="list-group mb-2">
                                        <label :id="'charge-' + charge.id" class="list-group-item" :disabled="charge.chargesTaxes == false
                                            " :key="charge" v-for="charge in chargesTaxes">
                                            <div class="d-flex align-items-center">
                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem" @change="addCharge(charge)" :disabled="charge.availableToInvoice ==
                                                    false
                                                    " :checked="charge.availableToInvoice ==
                                    false
                                    " />
                                                <div class="d-flex align-items-center justify-content-between ms-3">
                                                    <div style="width: 80px">
                                                        {{ charge.date }}
                                                    </div>
                                                    <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                    <span>{{
                                                        charge.description +
                                                        " (" +
                                                        formatCurrency(
                                                            charge.price,
                                                        defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="chargesAdvancePayments.length > 0" class="invoice-add-charges">
                                    <p>
                                        <small>Selecciona los anticipos que
                                            quieras añadir a la factura</small>
                                    </p>
                                    <div class="list-group mb-2">
                                        <label :id="'charge-' + charge.id" class="list-group-item" :disabled="charge.availableToInvoice ==
                                            false
                                            " :key="charge" v-for="charge in chargesAdvancePayments">
                                            <div class="d-flex align-items-center">
                                                <input class="form-check-input m-0" type="checkbox" style="font-size: 1.3rem" @change="addCharge(charge)" :disabled="charge.availableToInvoice ==
                                                    false
                                                    " :checked="charge.availableToInvoice ==
                                    false
                                    " />
                                                <div class="d-flex align-items-center justify-content-between ms-3">
                                                    <div style="width: 80px">
                                                        {{ charge.date }}
                                                    </div>
                                                    <i class="fa-regular fa-arrow-right-long mx-2"></i>
                                                    <span>{{
                                                        "ANTICIPO: " +
                                                        charge.description +
                                                        " (" +
                                                        formatCurrency(
                                                            charge.price,
                                                            defaultCurrency
                                                        ) +
                                                        ")"
                                                        }}</span>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="isNewCharge">
                            <form id="formNewCharge" @change="updatePrice">
                                <div class="form-group mb-3">
                                    <label class="form-label">Selecciona una habitación</label>
                                    <select class="form-control" v-model="newCharge.bookingRoom" required>
                                        <option :key="item" v-for="item in bookingrooms" :value="item.id">
                                            <span v-if="item.room != null">{{
                                                item.room.name
                                            }}</span>
                                            <span v-else>{{
                                                item.roomType.name
                                            }}</span>
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group mb-3">
                                    <label class="form-label">Selecciona un extra</label>
                                    <select class="form-control" v-model="newCharge.extra" required @change="updateExtra">
                                        <option value="0" selected>Otro</option>
                                        <option :key="item" v-for="item in extras.filter(item => item.status)" :value="item.id">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="form-label">Descripción</label>
                                    <input type="text" class="form-control" v-model="newCharge.description" required />
                                </div>
                                <div class="row">
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">Segmentación</label>
                                        <select class="form-control" v-model="newCharge.segment" required>
                                            <option :key="item" v-for="item in segments" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">Cantidad por día</label>
                                        <input type="number" class="form-control" v-model="newCharge.quantity" required />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">Precio unitario
                                            ({{ defaultCurrency }})</label>
                                        <input type="text" class="form-control" v-model="newCharge.price_unit" required />
                                        <small v-if="invoiceInfo.prices_include_tax" class="text-muted" style="font-style: italic;">Precio
                                            con impuesto
                                            incluido</small>
                                        <small v-else class="text-muted" style="font-style: italic;">Precio SIN impuesto
                                            incluido</small>
                                    </div>
                                    <div class="form-group mb-3 col-6">
                                        <label class="form-label">IVA</label>
                                        <select class="form-control" v-model="newCharge.iva" required>
                                            <option value="0">0%</option>
                                            <option value="4">4%</option>
                                            <option value="6">6%</option>
                                            <option value="8">8%</option>
                                            <option value="10">10%</option>
                                            <option value="19">19%</option>
                                            <option value="21">21%</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="form-label">Indicar fechas</label>
                                    <Calendar :attributes="attributes" :is-required="true" @dayclick="onDayClick" style="width: 100%" />
                                </div>
                                <div class="form-group mb-3">
                                    <div>
                                        <strong>Total:
                                            {{ newCharge.price + ' ' + defaultCurrency }} </strong>
                                    </div>
                                    <small v-if="invoiceInfo.prices_include_tax" class="text-muted" style="font-style: italic;">Impuestos
                                        incluidos</small>
                                    <small v-else class="text-muted" style="font-style: italic;">Impuestos NO incluidos</small>
                                </div>
                                <div v-if="error_message" class="alert alert-danger" role="alert">
                                    {{ error_message }}
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer border-0">
                        <a type="button" class="btn btn-link text-decoration-none text-primary" data-bs-dismiss="modal">Cerrar</a>
                        <button v-if="!isNewCharge" id="submitAddChargesToInvoice" type="submit" class="btn btn-primary" @click.stop.prevent="addChargesToInvoice">
                            Guardar
                        </button>
                        <button v-else id="submitAddNewCharge" type="submit" class="btn btn-primary" @click.stop.prevent="addNewChargeToInvoice">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import InvoiceService from "@/services/invoice.service";
import BookingService from "@/services/booking.service";
import RoomService from "@/services/room.service";

import Swal from "sweetalert2";

export default {
    name: "AddCharge",
    props: {
        invoice: Object,
        invoiceInfo: Object,
        booking: Object,
        extras: Array,
        segments: Array,
    },
    data() {
        return {
            bookingrooms: [],
            newCharge: {
                booking: this.booking.id,
                bookingRoom: null,
                invoice: this.invoice.id,
                isExtra: true,
                extra: 0,
                description: null,
                price_unit: 0,
                price: 0,
                persons: 0,
                quantity: 1,
                segment: null,
                iva: "21",
                isOld: false,
                date: new Date(),
            },
            masks: {
                input: "YYYY-MM-DD",
            },
            days: [],
            error_message: null,
            isNewCharge: false,
            charges: [],
            chargesTaxes: [],
            chargesAdvancePayments: [],
            addCharges: {
                invoice: this.invoice.id,
                charges: [],
            },
            defaultCurrency: ''
        };
    },
    computed: {
        dates() {
            return this.days.map((day) => day.date);
        },
        attributes() {
            return this.dates.map((date) => ({
                highlight: true,
                dates: date,
            }));
        },
    },
    methods: {
        async start() {
            await this.getBookingCharges();
            await this.getBookingChargesTaxes();
            await this.getBookingChargesAdvancePayment();
            await this.getBookingrooms();
        },

        async getBookingCharges() {
            var response = await BookingService.getBookingCharges({
                booking: this.booking.id,
            });
            this.charges = response.data;

            // console.log(this.charges, "charges")
        },

        async getBookingChargesTaxes() {
            var response = await BookingService.getBookingChargesTaxes({
                booking: this.booking.id,
            });
            this.chargesTaxes = response.data;
            // console.log(this.chargesTaxes, "chargesTaxes")
        },

        async getBookingChargesAdvancePayment() {
            var response = await BookingService.getBookingChargesAdvancePayment(
                { booking: this.booking.id }
            );
            this.chargesAdvancePayments = response.data;
            // console.log(this.chargesAdvancePayments, "chargesAdvancePayments")
        },

        optionCharge(value) {
            this.isNewCharge = value;
        },

        addCharge(item) {
            var input = window.$("#charge-" + item.id + " input");
            if (input.is(":checked")) {
                this.addCharges.charges.push(item.id);
                window.$("#charge-" + item.id).addClass("active");
            } else {
                const indexOfObject = this.addCharges.charges.findIndex(
                    (object) => {
                        return object.id === item.id;
                    }
                );
                this.addCharges.charges.splice(indexOfObject, 1);
                window.$("#charge-" + item.id).removeClass("active");
            }
            // console.log(this.addCharges.charges)
        },

        async getBookingrooms() {
            this.bookingrooms = this.booking.rooms;
            for (var i = 0; i < this.bookingrooms.length; i++) {
                var br = this.bookingrooms[i];

                if (br.room > 0) {
                    var response = await RoomService.getRoom(br.room);
                    this.bookingrooms[i].room = response.data;
                }
            }
            // console.log(this.bookingrooms)
        },

        async updatePrice() {
            var price = this.newCharge.price_unit;
            price = price * this.newCharge.quantity;
            price = price * this.days.length;
            this.newCharge.price = Math.round(price * 100) / 100;
        },

        async updateExtra() {
            var extraID = this.newCharge.extra;
            if (extraID > 0) {
                for (var i = 0; i < this.extras.length; i++) {
                    if (this.extras[i].id == extraID) {
                        this.newCharge.description = this.extras[i].name;
                        this.newCharge.segment = this.extras[i].segment;
                        this.newCharge.price_unit = this.extras[i].price;
                        this.newCharge.iva = this.extras[i].iva;
                        break;
                    }
                }
            } else {
                this.newCharge.description = null;
                this.newCharge.segment = null;
                this.newCharge.iva = 21;
                this.newCharge.price_unit = 0;
            }
        },

        async addChargesToInvoice() {
            window.$("#submitAddChargesToInvoice").empty();
            window
                .$("#submitAddChargesToInvoice")
                .append(
                    '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                );

            if (this.charges == "") {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Debes seleccionar al menos un cargo.",
                    confirmButtonColor: "#0091ff",
                });
            } else {
                var response = await InvoiceService.addChargesToInvoice(
                    this.invoice.id,
                    this.addCharges
                );

                if (response.status == 200) {
                    window.$("#modalAddCharge").hide();
                    Swal.fire({
                        icon: "success",
                        title: "Cargo añadido",
                        confirmButtonColor: "#0091ff",
                    }).then((result) => {
                        this.$router.go();
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                        confirmButtonColor: "#0091ff",
                    });
                }
            }
            window.$("#submitAddChargesToInvoice").empty();
            window.$("#submitAddChargesToInvoice").append("Guardar");
        },

        async addNewChargeToInvoice() {
            this.error_message = null;
            if (this.days.length < 1) {
                this.error_message = "Debes seleccionar al menos una fecha.";
            } else {
                window.$("#submitAddNewCharge").empty();
                window
                    .$("#submitAddNewCharge")
                    .append(
                        '<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>Guardando'
                    );

                if (this.newCharge.bookingRoom == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Debes seleccionar una habitación.",
                        confirmButtonColor: "#0091ff",
                    });
                } else if (this.newCharge.description == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Debes añadir una descripción.",
                        confirmButtonColor: "#0091ff",
                    });
                } else if (this.newCharge.segment == null) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Debes indicar un segmento.",
                        confirmButtonColor: "#0091ff",
                    });
                } else {
                    this.newCharge.date = this.formatDate(
                        this.newCharge.date,
                        false,
                        true
                    );

                    this.newCharge.dates = [];
                    for (var i = 0; i < this.days.length; i++) {
                        this.newCharge.dates.push(this.days[i].id);
                    }

                    this.newCharge.price_unit = parseFloat(
                        this.newCharge.price_unit
                    );
                    this.newCharge.price = parseFloat(this.newCharge.price);

                    var response = await InvoiceService.addNewChargeToInvoice(
                        this.invoice.id,
                        this.newCharge
                    );

                    if (response.status == 200) {
                        window.$("#modalAddCharge").hide();
                        Swal.fire({
                            icon: "success",
                            title: "Cargo añadido",
                            confirmButtonColor: "#0091ff",
                        }).then((result) => {
                            this.$router.go();
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                            confirmButtonColor: "#0091ff",
                        });
                    }
                }
                window.$("#submitAddNewCharge").empty();
                window.$("#submitAddNewCharge").append("Guardar");
            }
        },

        formatDate(date, hour = false, api = false) {
            var months = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];
            const d = new Date(date);
            var result = "";
            if (api) {
                result =
                    d.getFullYear() +
                    "-" +
                    (d.getMonth() + 1) +
                    "-" +
                    d.getDate();
            } else if (hour) {
                result =
                    d.getDate() +
                    " " +
                    months[d.getMonth()] +
                    " " +
                    d.getFullYear() +
                    " - " +
                    d.getHours() +
                    ":" +
                    (d.getMinutes() < 10 ? "0" : "") +
                    d.getMinutes() +
                    ":" +
                    (d.getSeconds() < 10 ? "0" : "") +
                    d.getSeconds();
            } else {
                result =
                    d.getDate() +
                    " " +
                    months[d.getMonth()] +
                    " " +
                    d.getFullYear();
            }

            return result;
        },



        onDayClick(day) {
            if (!day.isDisabled) {
                const idx = this.days.findIndex((d) => d.id === day.id);
                if (idx >= 0) {
                    this.days.splice(idx, 1);
                } else {
                    this.days.push({
                        id: day.id,
                        date: day.date,
                    });
                }
                this.updatePrice();
            }
        },
    },
    created() {
        this.start();
    },
};
</script>