<template>
    <div>
        <div v-if="!loading">
            <div :key="index" v-for="(item, index) in mutationRoomSelect" class="mb-2">
                <div class="room-charges">
                    <h6>
                        <i class="fas fa-bed me-2"></i>{{ "Habitación " + item.roomName }}
                    </h6>
                    <div class="mb-2">Tarifa: {{ item.rateName }}</div>
                </div>
                <div :key="RE" v-for="(RE, i) in mutationRoomSelect[index].extras">
                    <CreateBookingEditExtraRow :index="index" :position="i" :extra="RE" :segments="segments" :checkin="checkin" :checkout="checkout" @editExtra="editExtra"
                        @removeExtra="removeExtra" />
                </div>
                <div class="d-grid gap-2 col-6 mx-auto my-3">
                    <button class="btn btn-sm btn-primary mt-3 text-uppercase" data-bs-toggle="modal" :data-bs-target="'#modalAddCharge' + index">
                        <i class="fa-regular fa-plus me-1"></i>Añadir extra
                    </button>
                </div>
                <hr />
                <!-- Modal Add Charge -->
                <div ref="modal" class="modal fade" :id="'modalAddCharge' + index" tabindex="-1">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content p-2">
                            <form @submit.prevent="addExtra(index)" @change="updatePrice">
                                <div class="modal-header border-0">
                                    <h5 class="modal-title" id="exampleModalLabel">
                                        Añadir Extra
                                    </h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group mb-3">
                                        <label class="form-label">Selecciona un extra</label>
                                        <select class="form-control" v-model="addCharge.extra" required @change="updateExtra">
                                            <option value="0" selected>
                                                Seleccionar Extra
                                            </option>
                                            <option :key="item" v-for="item in extras.filter(item => item.status)" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Descripción</label>
                                        <input type="text" class="form-control" v-model="addCharge.description" required />
                                    </div>
                                    <div class="row">
                                        <div class="form-group mb-3 col-6">
                                            <label class="form-label">Segmentación</label>
                                            <select class="form-control" v-model="addCharge.segment" required>
                                                <option :key="item" v-for="item in segments" :value="item.id">
                                                    {{ item.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-3 col-6">
                                            <label class="form-label">Cantidad por día</label>
                                            <input type="number" class="form-control" v-model="addCharge.quantity" required />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group mb-3 col-6">
                                            <label class="form-label">Precio unitario ({{
                                                defaultCurrency }})</label>
                                            <input type="text" class="form-control" v-model="addCharge.price_unit" required />
                                        </div>
                                        <div class="form-group mb-3 col-6">
                                            <label class="form-label">IVA</label>
                                            <select class="form-control" v-model="addCharge.iva" required>
                                                <option value="0">0%</option>
                                                <option value="4">4%</option>
                                                <option value="6">6%</option>
                                                <option value="8">8%</option>
                                                <option value="10">10%</option>
                                                <option value="19">19%</option>
                                                <option value="21">21%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label class="form-label">Seleccionar Fechas</label>
                                        <Calendar :attributes="attributes" :min-page="minPage" :max-page="maxPage" :is-required="true" @dayclick="onDayClick" style="width: 100%" />
                                    </div>
                                    <div class="form-group mb-3 text-center">
                                        <h4>
                                            <strong>Precio total:
                                                {{ addCharge.price + ' ' + defaultCurrency }}
                                            </strong>
                                        </h4>
                                    </div>
                                    <div v-if="error_message" class="alert alert-danger" role="alert">
                                        {{ error_message }}
                                    </div>
                                </div>
                                <div class="modal-footer border-0">
                                    <a type="button" class="btn btn-link text-decoration-none text-primary" data-bs-dismiss="modal">Cerrar</a>
                                    <button id="submitAddCharge" type="submit" class="btn btn-primary">
                                        Añadir
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center mt-5">
            <p><small>Cargando información...</small></p>
            <div class="d-flex justify-content-center mt-5">
                <div id="spinner-search" class="spinner-border text-primary" role="status" style="width: 3rem; height: 3rem">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExtraService from "../../services/extra.service";
import SegmentService from "../../services/segment.service";

import CreateBookingEditExtraRow from "./CreateBookingEditExtraRow.vue";

export default {
    name: "ExtrasCreateBooking",
    emits: ["updateRoomSelected", "updateTotalPrice", "removeTotalPrice"],
    props: {
        roomSelected: Array,
        checkin: String,
        checkout: String,
    },
    components: {
        CreateBookingEditExtraRow: CreateBookingEditExtraRow,
    },
    data() {
        return {
            extras: [],
            mutationRoomSelect: [],
            segments: [],
            addCharge: {
                isExtra: true,
                extra: 0,
                description: null,
                price_unit: 0,
                price: 0,
                quantity: 1,
                segment: null,
                iva: "21",
                isOld: false,
                dates: [],
            },
            days: [],
            minPage: {
                month: parseInt(new Date(this.checkin).getMonth()) + 1,
                year: new Date(this.checkin).getFullYear(),
            },
            maxPage: {
                month: parseInt(new Date(this.checkout).getMonth()) + 1,
                year: new Date(this.checkout).getFullYear(),
            },
            error_message: null,
            loading: true,
            attributes: [],
            defaultCurrency: ''
        };
    },
    computed: {
        dates() {
            return this.days.map((day) => day.date);
        },
    },
    methods: {
        async start() {
            this.loading = true;
            await this.getExtras();
            await this.getSegments();
            this.mutationRoomSelect = this.roomSelected;
            // console.log(this.mutationRoomSelect, "mutationRoomSelect");

            // Añadmimos por defecto las fechas de la reserva
            var arr = [];
            const arrDates = this.getDates(
                new Date(this.checkin),
                new Date(this.checkout)
            );
            arrDates.forEach(function (date) {
                arr.push(date.toISOString().split("T")[0]);
            });

            this.attributes.push({
                highlight: true,
                dates: arr,
            });

            this.loading = false;
        },

        getDates(startDate, endDate) {
            const dates = [];
            let currentDate = startDate;
            const addDays = function (days) {
                const date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
            while (currentDate <= endDate) {
                dates.push(currentDate);
                currentDate = addDays.call(currentDate, 1);
            }
            return dates;
        },

        async getExtras() {
            var response = await ExtraService.getAllExtras();
            this.extras = response.data;
            // console.log(this.extras, "Extras")
        },

        async getSegments() {
            var response = await SegmentService.getAllSegments();
            this.segments = response.data;
        },

        checkExtra(arr, obj) {
            var i;
            for (i = 0; i < arr.length; i++) {
                if (arr[i].id === obj.id) {
                    return true;
                }
            }
            return false;
        },

        async updatePrice() {
            // console.log("Update Price");
            var price = parseFloat(
                this.addCharge.price_unit.toString().replace(",", ".")
            );
            price = price * this.addCharge.quantity;
            price = price * this.attributes[0].dates.length;
            this.addCharge.price = Math.round(price * 100) / 100;
        },

        async updateExtra() {
            var extraID = this.addCharge.extra;
            if (extraID > 0) {
                for (var i = 0; i < this.extras.length; i++) {
                    if (this.extras[i].id == extraID) {
                        this.addCharge.description = this.extras[i].name;
                        this.addCharge.segment = this.extras[i].segment;
                        this.addCharge.price_unit = parseFloat(
                            this.extras[i].price.toString().replace(",", ".")
                        );
                        this.addCharge.iva = this.extras[i].iva;
                        break;
                    }
                }
            } else {
                this.addCharge.description = null;
                this.addCharge.segment = null;
                this.addCharge.iva = 21;
                this.addCharge.price_unit = 0;
            }
        },

        async removeExtra(index, position) {
            var oldExtraPrice =
                this.mutationRoomSelect[index].extras[position].price;

            // Eliminamos el extra
            this.mutationRoomSelect[index].extras.splice(position, 1);

            // Actualizamos precio total de la habitación
            this.mutationRoomSelect[index].priceTotal = (
                parseFloat(this.mutationRoomSelect[index].priceTotal) -
                parseFloat(oldExtraPrice)
            ).toFixed(2);

            // Enviamos cambios
            await this.$emit("removeTotalPrice", oldExtraPrice);
            await this.$emit("updateRoomSelected", this.mutationRoomSelect);
        },

        async editExtra(index, position, extra, oldExtraPrice) {
            var newExtraPrice = extra.price;

            // Actualizamos la info del extra
            this.mutationRoomSelect[index].extras[position] = extra;

            // Actualizamos precio total de la habitación
            this.mutationRoomSelect[index].priceTotal = (
                parseFloat(this.mutationRoomSelect[index].priceTotal) +
                parseFloat(newExtraPrice) -
                parseFloat(oldExtraPrice)
            ).toFixed(2);

            // Enviamos cambios
            await this.$emit("removeTotalPrice", oldExtraPrice);
            await this.$emit("updateTotalPrice", newExtraPrice);
            await this.$emit("updateRoomSelected", this.mutationRoomSelect);
        },

        async addExtra(index) {
            this.error_message = null;
            if (this.addCharge.extra == 0) {
                this.error_message = "Debes seleccionar un extra.";
            } else if (this.attributes[0].dates.length < 1) {
                this.error_message = "Debes seleccionar al menos una fecha.";
            } else {
                this.addCharge.dates = this.attributes[0].dates;

                this.mutationRoomSelect[index].priceTotal = (
                    parseFloat(this.mutationRoomSelect[index].priceTotal) +
                    parseFloat(this.addCharge.price)
                ).toFixed(2);

                this.mutationRoomSelect[index].extras.push(this.addCharge);
                this.$emit("updateTotalPrice", this.addCharge.price);
                this.$emit("updateRoomSelected", this.mutationRoomSelect);
                window.$("#modalAddCharge" + index).hide();
                window.$("body").removeClass("modal-open");
                window.$(".modal-backdrop").remove();
                this.resetModalFields();
            }
        },

        onDayClick(day) {
            if (!day.isDisabled) {
                const idx = this.attributes[0].dates.findIndex(
                    (d) => d === day.id
                );
                if (idx >= 0) {
                    this.attributes[0].dates.splice(idx, 1);
                } else {
                    this.attributes[0].dates.push(day.id);
                }
                this.updatePrice();
            }
        },

        resetModalFields() {
            // console.log("Reset Modal")
            this.addCharge = {
                isExtra: true,
                extra: 0,
                description: null,
                price_unit: 0,
                price: 0,
                quantity: 1,
                segment: null,
                iva: "21",
                isOld: false,
            };
        },
    },
    created() {
        this.defaultCurrency = localStorage.getItem('currency') || 'EUR';
        this.start();
    },
    mounted() {
        window
            .$(this.$refs.modal)
            .on("hidden.bs.modal", this.resetModalFields());
    },
};
</script>