<template>
    <div class="h-100">
        <div id="submitCheckinOnlineBooking" class="booking-footer-button" data-bs-toggle="modal" data-bs-target="#modalCheckinOnlineBooking" style="position: relative">

            <i class="fa-regular fa-paper-plane-top"></i>
            <div class="title">Check-in Online</div>

        </div>

        <!-- Modal Checkin Online -->
        <div v-if="!loading" class="modal fade" id="modalCheckinOnlineBooking" tabindex="-1" aria-labelledby="modalCheckinOnlineBooking" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Check-in Online reserva {{ "#" + booking.id }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div class="col-md-12 mb-3">
                            <label class="form-label">¿A qué correo quieres enviar el email de check-in
                                online? </label>
                            <input type="email" class="form-control" v-model="email" />
                        </div>
                        <div class="input-group input-group-sm  mb-3">
                            <input type="text" class="badge-light-info form-control input-sm" v-model="link" disabled>
                            <button class="btn btn-info" type="button" id="button-copy-link" @click="copyLink"><i class="fa-regular fa-clipboard me-2"></i>{{
                                buttonCopyText }}</button>
                        </div>

                        <div v-if="!loading">

                            <div class="alert alert-warning" role="alert">
                                Si realizas cambios en la plantilla de email, recuerda pulsar en el
                                botón de <strong>Guardar cambios.</strong>
                            </div>
                            <button type="button" class="btn btn-primary" v-on:click="saveDesign">
                                Guardar cambios
                            </button>
                            <EmailEditor ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link text-primary" data-bs-dismiss="modal">
                            Cerrar
                        </button>
                        <button id="checkinOnlineBookingBtn" type="submit" class="btn btn-primary" @click.stop.prevent="checkinOnlineBooking()">
                            Enviar email
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import InfoService from "../../services/info.service";
import BookingService from "../../services/booking.service";
import { EmailEditor } from "vue-email-editor";

export default {
    name: "CheckinOnlineBooking",
    props: {
        booking: Object,
    },
    components: {
        EmailEditor,
    },
    data() {
        return {
            loading: true,
            info: null,
            email: this.booking.email,
            htmlEmail: "",
            link: "https://checkin.hospedium.io/booking/" + this.booking.token,
            buttonCopyText: 'Copiar link',
        };
    },
    methods: {
        async start() {
            this.loading = true;

            await this.getInfo();
            var content = JSON.parse(this.info.content);
            this.htmlEmail = content["html"];
            //console.log("HELLOOOO")
            this.loading = false;
        },

        async checkinOnlineBooking() {
            window.$("#checkinOnlineBookingBtn").attr("disabled", "true");

            var response = await BookingService.checkinOnlineBooking({
                booking: this.booking.id,
                email: this.email,
                htmlEmail: this.htmlEmail,
            });

            if (response.status == 200) {
                Swal.fire({
                    title: "¡Enviado!",
                    text: "Check-In Online enviado correctamente",
                    icon: "success",
                    confirmButtonColor: "#0091ff",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.go();
                    }
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Ha ocurrido un error. Vuelve a intentarlo. Si el error persiste ponte en contacto con soporte@hospedium.com",
                    confirmButtonColor: "#0091ff",
                });
            }

            window.$("#checkinOnlineBookingBtn").removeAttr("disabled");
        },

        async getInfo() {
            var response = await InfoService.getInfo({
                key_name: "email-checkin-online",
            });
            this.info = response.data;
            //console.log(this.info, "Info")
        },


        editorLoaded() {
            // console.log("editorLoaded");
            // Pass the template JSON here
            var content = JSON.parse(this.info.content);
            this.$refs.emailEditor.editor.loadDesign(content["design"]);
        },
        // called when the editor has finished loading
        editorReady() {
            // console.log("editorReady");
        },

        async saveDesign() {
            this.$refs.emailEditor.editor.exportHtml((data) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Se ha actualizado correctamente.",
                    showConfirmButton: false,
                    timer: 1500,
                    backdrop: false,
                });

                this.htmlEmail = data.html;
            });
        },
        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.link);
                // Cambia el texto del botón temporalmente
                this.buttonCopyText = '¡Enlace Copiado!';
                setTimeout(() => {
                    this.buttonCopyText = 'Copiar link';
                }, 2000); // Cambia de nuevo después de 2 segundos
            } catch (err) {
                console.error('Failed to copy: ', err);
                // Manejar el error o mostrar un mensaje al usuario
            }
        }
    },
    created() {
        this.start();
    },
};
</script>